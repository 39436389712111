import React, { useEffect, useRef, useState } from "react"

import clsx from "clsx"
import { createPortal } from "react-dom"
// import classes from "./SlidingSheet.module.css"
import { createUseStyles } from "react-jss"

import { useScrollShiftOffset } from "utils/hooks/useScrollShiftOffset"

const useStyles = createUseStyles(theme => ({
  "@keyframes fade-in": { "0%": { opacity: 0 }, "100%": { opacity: 1 } },
  "@keyframes fade-out": { "0%": { opacity: 1 }, "100%": { opacity: 0 } },
  "@keyframes slide-up": {
    "0%": { transform: "translateY(100%)" },
    "100%": { transform: "translateY(0%)" },
  },
  "@keyframes slide-down": {
    "0%": { transform: "translateY(0%)" },
    "100%": { transform: "translateY(100%)" },
  },
  "@keyframes slide-left": {
    "0%": { transform: "translateX(100%)" },
    "100%": { transform: "translateX(0%)" },
  },
  "@keyframes slide-right": {
    "0%": { transform: "translateX(0%)" },
    "100%": { transform: "translateX(100%)" },
  },
  "@keyframes slide-right-left": {
    "0%": { transform: "translateX(-100%)" },
    "100%": { transform: "translateX(0%)" },
  },
  "@keyframes slide-left-to-right": {
    "0%": { transform: "translateX(0%)" },
    "100%": { transform: "translateX(-100%)" },
  },
  backdrop: {
    backgroundColor: "rgba(0, 0, 0, 0.25)",
    width: "100%",
    height: "100%",
    position: "fixed",
    zIndex: 25,
    inset: "0",
  },
  hidden: { display: "none" },
  backdropActive: { animation: "$fade-in 300ms forwards" },
  base: {
    zIndex: theme.zIndex.dialog,
    backgroundColor: "white",
    position: "absolute",
    overflow: "auto",
  },
  bottomDismissible: {
    borderStartStartRadius: "16px",
    borderStartEndRadius: "16px",
  },
  fromEnd: {
    animation: "$slide-right 300ms forwards, $fade-out 300ms forwards",
    height: "100%",
    right: "0",
    top: "0",
    "--enter-animation": "$slide-left",
  },
  fromStart: {
    animation: "$slide-left-to-right 300ms forwards, $fade-out 300ms forwards",
    height: "100%",
    left: "0",
    top: "0",
    "--enter-animation": "$slide-right-left",
  },
  fromBottom: {
    animation: "$slide-down 300ms forwards, $fade-out 300ms forwards",
    bottom: "0",
    "--enter-animation": "$slide-up",
    left: "50%",
    translate: "-50%",
    // width: "100%",
  },
  fullScreen: { width: "100%", height: "100%" },
  enter: {
    animation: "var(--enter-animation) 300ms forwards, $fade-in 300ms forwards",
  },
}))

type Props = {
  children?: React.ReactNode
  onClose: VoidFunction
  open: boolean
  /**
   * `bottom`: Sheet will be opened from the bottom edge of the screen
   *
   * `end`: Sheet will be opened from the right edge (in LTR mode) of the screen
   *
   * `start`: Sheet will be opened from the left edge (in LTR mode) of the screen
   */
  from?: "bottom" | "start" | "end"
  className?: string
  /**
   * This should be supplied as `true` to enable drag-to-dismiss gestures and for UI consistency
   * Dismissible sheets have rounded corners (This is a TODO)
   */
  isDismissible?: boolean
  /**
   * Unmounts children on exit
   */
  unmountOnExit?: boolean
  /**
   * If true, then the sheet escapes the flow of the document and gets mounted in the body
   */
  mountOnBody?: boolean
  /**
   * When `true`, the sheet takes up the entire screen
   */
  fullScreen?: boolean
  /**
   * This class is for container
   */
  containerClassName?: string
}

/**
 * What will this component cater?
 * - (Done) Sheet which opens from the bottom
 * - (Done) Sheet which opens from the side
 * - (Done) Full screen sheet
 * - (Done) Dismiss on click outside
 * - Dismiss on drag.
 * - (Done) Conditionally rounded corners. Sheets that can be dismissed have rounded corners by default.
 *   Sheets that cannot be dismissed have squared corners.
 *
 * TODO: Manage focus. Trap focus, return focus, manage autofocus
 * TODO: Accessibility
 * TODO: Backdrop exit animation not working
 * TODO: Swipe to close
 * TODO: Add fullscreen modal support
 *
 * Possible API
 * <SlidingSheet from="end" closeOnOverlayClick open onClose keepMounted=false returnFocusTo=Element />
 */
const SlidingSheet = ({
  className,
  mountOnBody = false,
  containerClassName,
  from = "bottom",
  open,
  unmountOnExit,
  fullScreen = false,
  onClose,
  children,
  isDismissible = true,
}: Props) => {
  const styles = useStyles()

  const [visibility, setVisibility] = useState<"visible" | "hidden">(
    open ? "visible" : "hidden"
  )

  // TODO: Add a guard which prevents user from specifying fullScreen and isDismissible together.
  /**
   * If a sheet is full-screen then border should not rounded
   */
  const timesOpened = useRef(0)

  const contentRef = useRef<HTMLDivElement>(null)
  const backdropRef = useRef<HTMLDivElement>(null)

  useScrollShiftOffset(open)

  useEffect(() => {
    if (open) {
      timesOpened.current += 1
      setVisibility("visible")
    } else {
      if (timesOpened.current === 0) return

      if (contentRef.current) {
        contentRef.current.addEventListener(
          "animationend",
          () => {
            setVisibility("hidden")
          },
          { once: true }
        )
      }
    }
  }, [open, contentRef])

  useEffect(() => {
    if (!isDismissible || !open) return

    const backdrop = backdropRef.current

    const handler = (e: Event) => {
      if (e.target === backdrop) {
        onClose()
      }
    }

    const keydownHandler = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        onClose()
      }
    }

    if (backdrop) {
      window.addEventListener("keyup", keydownHandler)
      backdrop.addEventListener("click", handler)
    }

    return () => {
      window.removeEventListener("keyup", keydownHandler)
      if (backdrop) backdrop.removeEventListener("click", handler)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, isDismissible])

  const component = (
    <span
      className={clsx({
        [styles.hidden]: visibility === "hidden",
      })}
    >
      <div
        className={clsx(containerClassName, {
          [styles.backdrop]: true,
          [styles.backdropActive]: visibility === "visible",
        })}
        ref={backdropRef}
      >
        <div
          className={clsx(className, {
            [styles.base]: true,
            [styles.fullScreen]: fullScreen,
            [styles.bottomDismissible]:
              from === "bottom" && isDismissible && !fullScreen,
            [styles.fromEnd]: from === "end",
            [styles.fromBottom]: from === "bottom",
            [styles.fromStart]: from === "start",
            [styles.enter]: open,
          })}
          ref={contentRef}
        >
          {visibility === "hidden" && unmountOnExit ? null : children}
        </div>
      </div>
    </span>
  )

  if (mountOnBody) return createPortal(component, document.body)

  return component
}

export default SlidingSheet
